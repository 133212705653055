import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { Link } from 'gatsby'

import Logo from './logo'
import Social from './social'
import MenuMain from './menu-main'
import MenuSecondary from './menu-secondary'
import ColorModeSwitcher from './color-mode-switcher'

const Wrapper = styled.div`
  box-sizing: border-box;
  padding: ${({ theme }) => theme.spacing['s0.5']}px
    ${({ theme }) => theme.spacing.s1}px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: ${({ theme }) => theme.colors.background};
  border-bottom: 1px solid ${({ theme }) => theme.colors.text};

  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (min-width: ${({ theme }) => theme.customBreakpoints.sidebar}) {
    ${({ theme: { sizes, spacing } }) => css`
      left: 0;
      right: auto;
      bottom: 0;
      width: ${sizes.sidebarWidth}px;
      padding: ${spacing.s2}px ${spacing.s1}px;

      box-shadow: none;
      flex-direction: column;
      align-items: stretch;
    `}
  }
`

const MenuWrapper = styled.div`
  @media (min-width: ${({ theme }) => theme.customBreakpoints.sidebar}) {
    flex: 1 1 auto;
    margin-top: ${({ theme }) => theme.spacing.s4}px;
  }
`

const FooterWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing.s2}px 0 0;

  display: none;
  @media (min-width: ${({ theme }) => theme.customBreakpoints.sidebar}) {
    display: block;
  }
`

const MenuMainWrapper = styled.nav`
  display: flex;
  font-size: 0.6em;

  & > * {
    padding: ${({ theme }) => theme.spacing['s0.25']}px;

    & + * {
      margin: 0 0 0 ${({ theme }) => theme.spacing['s0.75']}px;
    }
  }

  @media (min-width: ${({ theme }) => theme.customBreakpoints.sidebar}) {
    flex-direction: column;
    font-size: 1em;

    & > * {
      padding: ${({ theme }) => theme.spacing['s0.5']}px 0 0;

      & + * {
        margin: ${({ theme }) => theme.spacing['s0.5']}px 0 0;
      }
    }
  }
`
const MenuSecondaryWrapper = styled.nav`
  display: none;
  @media (min-width: ${({ theme }) => theme.customBreakpoints.sidebar}) {
    display: flex;
    flex-direction: column;
    margin-top: ${(props) => props.theme.spacing.s4}px;

    & > * {
      padding: ${({ theme }) => theme.spacing['s0.5']}px 0 0;

      & + * {
        margin: ${({ theme }) => theme.spacing['s0.5']}px 0 0;
      }
    }
  }
`

const ColorModeWrapper = styled.div`
  width: 26px;
  @media (min-width: ${({ theme }) => theme.customBreakpoints.sidebar}) {
    position: fixed;
    right: ${({ theme }) => theme.spacing.s2}px;
    top: ${({ theme }) => theme.spacing.s2}px;
    width: 40px;
  }
`

export default function FloatingMenu() {
  return (
    <Wrapper>
      <Link to="/">
        <Logo />
      </Link>
      <MenuWrapper>
        <MenuMainWrapper>
          <MenuMain />
        </MenuMainWrapper>
        <MenuSecondaryWrapper>
          <MenuSecondary />
        </MenuSecondaryWrapper>
      </MenuWrapper>
      <FooterWrapper>
        <Social />
      </FooterWrapper>
      <ColorModeWrapper>
        <ColorModeSwitcher />
      </ColorModeWrapper>
    </Wrapper>
  )
}
