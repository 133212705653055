import React from 'react'
import { Link } from 'gatsby'

export default function MenuSecondary() {
  return (
    <>
      <Link to="/legal-disclosure">Legal disclosure</Link>
    </>
  )
}
