import React from 'react'
import propTypes from 'prop-types'
import styled from '@emotion/styled'

import github from 'simple-icons/icons/github'
import twitter from 'simple-icons/icons/twitter'
import instagram from 'simple-icons/icons/instagram'
import linkedin from 'simple-icons/icons/linkedin'

const icons = {
  github,
  twitter,
  instagram,
  linkedin,
}

const SocialWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  max-width: 400px;
`

const IconLink = styled.a`
  flex: 0 0 auto;
  display: block;
  width: 20px;
  padding: ${({ theme }) => theme.spacing['s0.5']}px;

  path {
    fill: ${({ theme }) => theme.colors.text};
    opacity: 0.4;
    transition: 0.3s any linear;
  }

  :hover path {
    fill: ${(props) => props.hover};
    opacity: 1;
  }
`

const LinkedIcon = function({ href, icon }) {
  const { svg, hex } = icons[icon]
  return (
    <IconLink
      href={href}
      hover={`#${hex}`}
      dangerouslySetInnerHTML={{ __html: svg }}
    />
  )
}

LinkedIcon.propTypes = {
  href: propTypes.string.isRequired,
  icon: propTypes.string.isRequired,
}

export default function social() {
  return (
    <SocialWrapper>
      <LinkedIcon
        icon="github"
        aria-label="Find me on Github"
        href="https://github.com/axe312ger"
      />
      <LinkedIcon
        aria-label="Find me on Linked In"
        icon="linkedin"
        href="https://www.linkedin.com/in/benedikt-r%C3%B6tsch-0a248a58/"
      />
      <LinkedIcon
        icon="twitter"
        aria-label="Find me on Twitter"
        href="https://twitter.com/axe312ger"
      />
      <LinkedIcon
        icon="instagram"
        aria-label="Find me on Instagram"
        href="https://www.instagram.com/timelapsa/"
      />
    </SocialWrapper>
  )
}
