import React from 'react'

import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Global, css } from '@emotion/core'
import { Styled } from 'theme-ui'

import Social from './social'
import MenuSecondary from './menu-secondary'
import FloatingMenu from './floating-menu'

const ContentWrapper = styled.div`
  box-sizing: border-box;
  margin: 0 auto;
  max-width: ${({ theme }) => theme.sizes.maxContentWidth}px;
  padding: ${({ theme }) => theme.spacing.s4}px
    ${({ theme }) => theme.spacing.s1}px;

  margin-top: 60px;
  @media (min-width: ${({ theme }) => theme.customBreakpoints.sidebar}) {
    margin-top: 0;
  }
`

const FooterWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing.s2}px 0 0;

  display: flex;
  justify-content: space-evenly;
  align-items: center;

  @media (min-width: ${({ theme }) => theme.customBreakpoints.sidebar}) {
    display: none;
  }
`

const FooterMenuWrapper = styled.nav``

const Layout = ({ children }) => {
  return (
    <Styled.root>
      <Global
        styles={(theme) => css`
          body {
            margin: 0;

            a {
              color: inherit;
              text-decoration: none;

              :visited {
                color: inherit;
              }
            }

            p a,
            nav a,
            a.more {
              position: relative;

              &:after {
                content: '';
                position: absolute;
                left: -4px;
                right: -4px;
                bottom: -4px;
                height: 4px;
                background: ${theme.colors.text};
                transition: 0.15s height ease-in-out;
                opacity: 0.2;
              }

              &:hover {
                &:after {
                  height: calc(100% + 4px);
                }
              }
            }
          }
        `}
      />
      <ContentWrapper>
        <main>{children}</main>
        <FooterWrapper>
          <Social />
          <FooterMenuWrapper>
            <MenuSecondary />
          </FooterMenuWrapper>
        </FooterWrapper>
      </ContentWrapper>
      <FloatingMenu />
    </Styled.root>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
