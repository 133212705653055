import React from 'react'
import styled from '@emotion/styled'

import LogoSVG from '../assets/logo.svg'

const Wrapper = styled.div``

const LogoWrapper = styled.div`
  min-width: 40px;

  & svg {
    display: block;

    & path {
      fill: ${({ theme }) => theme.colors.text};
    }
  }
`

const Name = styled.div`
  display: none;
  @media (min-width: ${({ theme }) => theme.customBreakpoints.sidebar}) {
    display: block;
    font-weight: bold;
    font-size: 1em;
    margin-top: ${({ theme }) => theme.spacing.s2}px;
  }
`

const Slogan = styled.div`
  font-size: 0.8em;
  display: none;
  @media (min-width: ${({ theme }) => theme.customBreakpoints.sidebar}) {
    display: block;
  }
`

export default function Logo() {
  return (
    <Wrapper>
      <LogoWrapper>
        <LogoSVG />
      </LogoWrapper>
      <Name>createdBy:TECHNOLOGY</Name>
      <Slogan>Electrons you can see.</Slogan>
    </Wrapper>
  )
}
