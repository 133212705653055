/** @jsx jsx */
import { jsx, useColorMode } from 'theme-ui'

import IconLight from 'svg-icon/dist/svg/weather/sunrise.svg'
import IconDark from 'svg-icon/dist/svg/weather/moonrise.svg'
import styled from '@emotion/styled'

const SwitcherLink = styled.a`
  display: block;
  cursor: pointer;

  svg {
    display: block;
    margin: 0 auto;

    path {
      fill: ${({ theme }) => theme.colors.text};
    }
  }
`

export default (props) => {
  const [mode, setMode] = useColorMode()
  return (
    <SwitcherLink
      {...props}
      onClick={(e) => {
        const next = mode === 'dark' ? 'light' : 'dark'
        setMode(next)
      }}
    >
      {mode === 'dark' ? <IconLight /> : <IconDark />}
    </SwitcherLink>
  )
}
