import React from 'react'
import { Link } from 'gatsby'

export default function MenuMain() {
  return (
    <>
      <Link to="/services">Services</Link>
      <Link to="/projects">Projects</Link>
      <Link to="/">Blog</Link>
      <Link to="/contact">Contact</Link>
    </>
  )
}
