import React from 'react'
import { graphql } from 'gatsby'
import * as PropTypes from 'prop-types'
import Helmet from 'react-helmet'

import { MDXProvider } from '@mdx-js/react'
import { MDXRenderer } from 'gatsby-plugin-mdx'

import Layout from '../components/layout'

function PageTemplate({ data }) {
  const blogPost = data.contentfulPage

  const {
    // heroContent,
    // heroMedia,
    content,
    title,
    metaDescription,
    // contentful_id: activePageId,
  } = blogPost

  return (
    <Layout>
      <Helmet
        /**
         * Meta information based on:
         * https://moz.com/blog/meta-data-templates-123
         * https://developer.apple.com/library/archive/documentation/AppleApplications/Reference/SafariWebContent/ConfiguringWebApplications/ConfiguringWebApplications.html
         */
        title={title}
        meta={[
          {
            property: 'og:title',
            content: title,
          },
          {
            name: 'description',
            content: metaDescription,
          },
          {
            property: 'og:description',
            content: metaDescription,
          },
          // heroImage && {
          //   property: 'twitter:image:src',
          //   content: `${seoImage.file.url}?w=1200&h=628&fit=fill`,
          // },
          // heroImage && {
          //   property: 'og:image',
          //   content: `${seoImage.file.url}?w=1200&h=630&fit=fill`,
          // },
        ].filter(Boolean)}
      />
      <MDXProvider>
        <MDXRenderer>{content.childMdx.body}</MDXRenderer>
      </MDXProvider>
    </Layout>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object.isRequired,
}

export default PageTemplate

export const pageQuery = graphql`
  query pageQuery($id: String!) {
    contentfulPage(id: { eq: $id }) {
      id
      contentful_id
      slug
      title
      metaDescription
      content {
        childMdx {
          body
        }
      }
    }
  }
`
